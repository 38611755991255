import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
} from "@angular/fire/firestore";
import { orderBy, query } from "@firebase/firestore";

import { Activity } from "../../models/activities/activities.interface";
import { getFunctions, httpsCallable } from '@angular/fire/functions';


@Injectable({
  providedIn: "root",
})
export class ActivityService {
  activities$: Observable<Activity[]>;
  activityTypePrettyMap = {
    lms: "LMS",
    lmsQuiz: "LMS Quiz",
    article: "Article",
    video: "Video",
    booster: "Booster",
    observeShadow: "Observation/Shadow",
    coachObservation: "Coach Observation",
    surveys: "Surveys",
    webinar: "Webinar",
    prerecordedLearnerVideos: "Prerecorded Learner Videos",
    recording: "Recording",
    other: "Other"
  }

  activityToCreate = new EventEmitter<any>();
  usersSelectedForActivity = [];

  loading: boolean;

  constructor(private firestore: Firestore) {}

  public getActivities(): Observable<Activity[]> {
    const notesRef = collection(this.firestore, "activities");
    return collectionData(notesRef, { idField: "id" }) as Observable<Activity[]>;
  }


  public getActivityById(id: string): Observable<Activity> {
    const bookRef = doc(this.firestore, `activities/${id}`);
    return docData(bookRef, { idField: 'id' }) as Observable<Activity>;
  }



  saveActivity(activity: any) {
    const functions = getFunctions();

    const addActivity = httpsCallable(functions, 'addActivity');

    return addActivity({body: activity}); 
  }

  updateActivityAction(activity: any, userId: string, skillId: string, activityId: string) {
    const functions = getFunctions();

    const updateActivityAction = httpsCallable(functions, 'updateActivityAction');

    return updateActivityAction({body: activity, userId: userId, skillId: skillId, activityId: activityId}); 
  }


  updateActivityDueOn(activity: any, userId: string, skillId: string, activityId: string) {
    const functions = getFunctions();

    const updateActivityDueOn = httpsCallable(functions, 'updateActivityDueOn');

    return updateActivityDueOn({body: activity, userId: userId, skillId: skillId, activityId: activityId}); 
  }

  addDuplicateLMSActivity(userId: string, skillId: string, activityId: string) {
    const functions = getFunctions();

    const addDuplicateLMSActivity = httpsCallable(functions, 'addDuplicateLMSActivity');

    return addDuplicateLMSActivity({userId, skillId, activityId}); 
  }

  addNote(userId: string, skillId: string, activityId: string, noteModel: any) {
    const functions = getFunctions();

    const addNote = httpsCallable(functions, 'addNote');

    return addNote({userId, skillId, activityId, noteModel}); 
  }

  canUserStartActivity(userId: string, skillId: string, activity: any) {
    const functions = getFunctions();

    const canUserStartActivity = httpsCallable(functions, 'canUserStartActivity');

    return canUserStartActivity({userId, skillId, activity}); 
  }

  getUsersToAddActivity() {
    // If the array is not empty, get the array from local storage and set the array and return it
      const arrStr = localStorage.getItem('arrOfUsersToAddActivity');
      if (!arrStr) {
        return [];
      }
      return JSON.parse(arrStr);

  }

  async markActivityCompletedCallable(userId: string, skillId: string, activityId: string) {
    console.log({userId, skillId, activityId})
    const functions = getFunctions();
  
    const markActivityCompletedCallable = httpsCallable(functions, 'markActivityCompletedCallable');
  
    return markActivityCompletedCallable({userId, skillId, activityId}); 
  }
  


  setUsersToAddActivity(arrOfUsers: any[]) {
    // Sets the array of users to the service variable
    // Also sets the array to local storage
    localStorage.setItem('arrOfUsersToAddActivity', JSON.stringify(arrOfUsers));
  }

  clearLocalStorage() {
    localStorage.clear();
  }
}
