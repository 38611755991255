<style>
  @media print {
    .page-break {
      page-break-before: always; /* Force a page break before elements with the class 'page-break' */
      background-color: #fff !important;
    }
    .noPrint {
      display: none;
    }
    .cardPrint {
      box-shadow: none !important;
      border-radius: 0px !important;
      border: none !important;
    }
  }
  .hero-heading-class {
    font-family: 'Poppins', sans-serif; 
    font-weight: 700;
    font-size: 2em;
    margin-right: 20%;
  }
  .feedback-section-title {
    font-family: 'Poppins', sans-serif; 
    font-weight: 700;
    font-size: 24px;
    margin-top: .5em;
    color: #8AA0CC;
  }
  .step-title {
    font-family: 'Poppins', sans-serif; 
    font-weight: 700;
    font-size: 36px;
    color: #C24D2C;
    display: inline-block;
  }
  
  .step-title-line {
    border-bottom: 4px solid #C24D2C;
    margin-left: 0.75em; 
    display: inline-block; 
    width: 16em; 
    margin-bottom: .75em;
  }


  .tableDataFormat {
    font-family: 'Poppins', sans-serif; 
    color: #454F59;
    font-weight: 375;
    line-height: 25px;
    padding: 10px; /* Adjust the padding as needed */
  }

  .step-table {
    margin-left: 0px; 
    padding-left: 0px;
    margin-top: 1em;
  }




</style>


<div _ngcontent-wma-c312="" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ng-star-inserted" *ngIf="surveyId == 'yNlh73iAWthcWYMnOogK' && (authService.checkUserRoles([Role.Coach]))">

  <div _ngcontent-wma-c212="" class="col-xs-12" style="background-color: #232E41;">
    <div _ngcontent-wma-c212="" class="header hero-heading">
      <h1 _ngcontent-wma-c212="" class="hero-heading-class ng-star-inserted">Inter-rater Reliability View</h1>
    </div>

  </div>

<div _ngcontent-wma-c312="" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 ng-star-inserted" style="display: inline-block; vertical-align:top">
  <div _ngcontent-wma-c212="" class="ng-star-inserted"><!--bindings={
"ng-reflect-ng-if": "false"
}-->


    <div _ngcontent-wma-c212="" class="col-xs-12" style="background-color: #232E41;">
      <div _ngcontent-wma-c212="" class="header hero-heading">
        <h1 _ngcontent-wma-c212="" class="hero-heading-class ng-star-inserted"><span _ngcontent-wma-c212=""
          style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: .5em; color: #fff; vertical-align: middle; padding-left: 10px;">
          Coach 1</span>
          <br/>The Purpose of <span
            _ngcontent-wma-c212="" class="text-emphasis">Role Clarification</span></h1>
      </div>
      <div _ngcontent-wma-c212="" class="header icon-heading">
        <div _ngcontent-wma-c212=""><i _ngcontent-wma-c212="" class="fa-solid fa-bullseye"
            style="color: #fff; font-size: 2em;"><span _ngcontent-wma-c212=""
              style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: .5em; color: #fff; vertical-align: middle; padding-left: 10px;">
              new user medium</span></i></div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(255, 255, 255);">
      <div _ngcontent-wma-c212="" class="step-title"> INTRODUCTION </div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">0.1 - </b> You recognized
                  an appropriate opportunity to use Role Clarification. The Role Clarification process should start at
                  the beginning of supervision, ideally during your first contact, to establish rapport and clarify
                  the rules, roles, and expectations. Research demonstrates that outcomes are improved when staff
                  ensures JIIs understand the purpose and expectations. </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="tableDataFormat"> &nbsp;&nbsp;&nbsp; There are no improvements to
                  report. </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(248, 248, 248);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">1</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" style="margin-bottom: 0px;" class="ng-star-inserted">STEP 1 - Identify the agency's
          (county's) goals &amp; purpose for supervision.</h3>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.1 - </b> You used
                  informal and conversational language to articulate the agency's purpose and goals for delivering
                  supervision services. </td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.2 - </b> In Step One,
                  you enhanced the collaborative and relationship-building dimensions of Role Clarification (RC) by
                  using a tone of voice conducive to creating engagement, building rapport, and expressing optimism
                  about the supervision process. </td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.3 - </b> Great job
                  fostering engagement early in the conversation!



                  <!--bindings={
"ng-reflect-ng-if": "false"
}-->
                </td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.4 - </b> Some JIIs
                  might believe that supervision exists solely to monitor and enforce their court/parole board
                  conditions (rules) or to look for violations that will send them back to jail or prison. You did a
                  fantastic job taking a moment to provide clarification on this misunderstanding. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.5 - </b> You did an
                  excellent job taking Step One to an advanced level by helping the JII recognize that all of the
                  required supervision tasks (i.e., The terms, conditions, and rules) represent the court's/parole
                  board's strategies to achieve the overarching goals of supervision. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.6 - </b> During Step
                  One, the JII shared additional information. You did an excellent job refraining from judgment and
                  advice, opting instead to respond by actively listening and acknowledging their comments and
                  concerns. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.7 - </b> In Step One,
                  the JII shared additional information. You did an outstanding job refraining from judgment and
                  advice, choosing instead to respond with expressions of optimism and instilling hope. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.8 - </b> You utilized
                  open-ended check-in questions to assess the JII's comprehension of the purpose and goals of
                  supervision. This is an excellent approach to ensure mutual understanding! <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.9 - </b> In Step One,
                  you incorporated an advanced approach by asking open-ended questions that encouraged the JII to
                  express any confusion or seek clarification. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.10 - </b> Great
                  strategy! Inviting the JII to share their thoughts on the purpose and goals of supervision is an
                  excellent way to assess their understanding and willingness to engage in the process. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object],[object Object"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.1 - </b> The initial
                  stage of Role Clarification involves briefly explaining the overarching purpose of supervision and
                  the agency's (county's, district's) objectives in delivering services to Justice-Involved
                  Individuals (JIIs). The description should align with the agency's mission statement, employing
                  broad and general language applicable to anyone under supervision. It's crucial to emphasize that
                  this step is not about the officer/staff or tailored to the specific JII. Be cautious not to
                  personalize the purpose and goals in this phase (e.g., "To ensure 'you' don't re-offend" or "To
                  equip 'you' with the skills 'you' need to change 'your' behavior."). Providing a general explanation
                  at this point minimizes potential defensiveness and promotes rapport-building. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(255, 255, 255);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">2</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" style="margin-bottom: 0px;" class="ng-star-inserted">STEP 2 - Ask the JII to
          identify what they hope to accomplish during supervision</h3>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">2.1 - </b> Inquiring
                  about the JII's personal goals is an excellent method for understanding their intrinsic motivators.
                  While it's acceptable to incorporate supervision goals into the conversation, be cautious not to
                  exclusively narrow the focus to supervision goals. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">2.1 - </b> You skillfully
                  prompted the JII to share their goals and aspirations. As you draw out and delve into their goals,
                  it's important to avoid imposing your own objectives for the JII, steering them toward specific
                  areas, or unintentionally influencing their thoughts. Step Two is dedicated to building rapport and
                  gaining a deeper understanding of the JII. Maintain a neutral stance in the conversation, allowing
                  the individual to freely discuss meaningful aspects of their life. It's perfectly acceptable if
                  their identified goals differ from the ones you may have in mind for them. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(248, 248, 248);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">3</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" style="margin-bottom: 0px;" class="ng-star-inserted">STEP 3 - Identify what your
          role is, as a representative of the agency, and what you hope to accomplish</h3>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.1 - </b> You explained
                  that you have several roles in supervising the JII. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.2 - </b> You described
                  your goals and what you hope to accomplish in working with the JII. This is an excellent way to
                  establish collaboration and help the person recognize the purpose of your interactions and any
                  interventions you may provide. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><br/><br/></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.4 - </b> You did an
                  outstanding job in Step Three by encouraging the JII to provide input. This segment of the
                  conversation presents an excellent opportunity to foster collaboration and involve them actively in
                  their supervision. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object],[object Object"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.1 - </b> Research shows
                  that staff who maintain a balanced approach to supervision have the best outcomes with the people
                  they work with. To achieve this, we must consistently balance or blend our dual roles. Help the JII
                  understand that, at all times, you will be acting in both a 'law-enforcement' (holding accountable,
                  monitoring compliance, reporting to the courts/parole board) and a 'social work' (providing
                  assistance and support, teaching skill-building, promoting positive behavioral change) capacity.
                  Subtle messages that imply that our role is contingent upon the JII's actions (e.g., 'I'm willing to
                  work with you and help you, unless...' or '...and then I'll have to take off my social work hat and
                  put on my law enforcement hat.') carry a veiled threat or warning that may harm the supervision
                  relationship. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break ng-star-inserted" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(255, 255, 255);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">4</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" style="margin-bottom: 0px;" class="ng-star-inserted">STEP 4 - Define the
          supervision process</h3>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.1 - </b> Great job
                  acknowledging that the "negotiable/non-negotiable" sub-step contributes to empowering individuals by
                  highlighting that they will have some control in their supervision (i.e., emphasizing autonomy). <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><br/><br/><br/></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.3 - </b> Great work
                  involving the JII in the discussion about negotiable and non-negotiable aspects of supervision. By
                  encouraging their input, you emphasized their autonomy and decision-making role in the process. This
                  approach helps build a sense of collaboration and shared responsibility in the supervision
                  relationship. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.4 - </b> You offered
                  examples of situations where the JII can make decisions and exercise control (negotiable) and where
                  they are not allowed to (non-negotiable). This is an excellent way to reinforce your point and
                  provide clarification. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.5 - </b> You did a
                  fantastic job highlighting a few non-negotiable items that may change due to the JII's actions.
                  Helping a JII recognize how reporting schedules (frequency and location), drug tests, and other
                  non-negotiable conditions may lessen in response to their behavior is empowering. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object],[object Object"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.1 - </b>
                  Justice-involved individuals who accurately understand the rules, roles, and expectations have
                  better outcomes than those who do not. Use the conversation in step four to encourage the JII to ask
                  questions, express concerns, and ensure understanding. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div><!--bindings={
"ng-reflect-ng-if": "true"
}-->
    <div _ngcontent-wma-c212="" class="step-section page-break ng-star-inserted" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(248, 248, 248);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">5</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" class="ng-star-inserted">STEP 5 - Identify and discuss expectations of
          confidentiality</h3><!--bindings={
"ng-reflect-ng-if": "true"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.1 - </b> When
                  explaining the expectations of confidentiality, you used clear and specific language to describe the
                  type of information that would be shared with others—an excellent way to avoid any potential
                  confusion. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.2 - </b> You excelled
                  in Step Five of Role Clarification by providing accurate and thorough information to the JII about
                  confidentiality. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.3 - </b> You did well
                  in explaining how releases of information (ROI) are connected to the expectations of
                  confidentiality. Including information about the expectations surrounding ROIs for treatment staff
                  or support individuals is a valuable addition to emphasize the connection. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.4 - </b> Great job
                  creating dialogue by inviting the person to share their thoughts and concerns about information
                  being shared. This approach helps to address any apprehensions the person may have and fosters open
                  communication. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object],[object Object"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.1 - </b> Collaborating
                  and sharing information with others on the team is always purposeful. Frequently, it helps us reach
                  one or more of the agency's overarching goals (e.g., informing the courts /parole board to protect
                  public safety and sharing concerns with treatment providers to promote behavior change). Therefore,
                  when describing the expectations for confidentiality, you should help the JII understand the purpose
                  and circumstances for sharing their information. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>






<div _ngcontent-wma-c312="" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 ng-star-inserted" style="display: inline-block; vertical-align:top">
  <div _ngcontent-wma-c212="" class="ng-star-inserted"><!--bindings={
"ng-reflect-ng-if": "false"
}-->
    <div _ngcontent-wma-c212="" class="col-xs-12" style="background-color: #232E41;">
      <div _ngcontent-wma-c212="" class="header hero-heading">
        <h1 _ngcontent-wma-c212="" class="hero-heading-class ng-star-inserted"><span _ngcontent-wma-c212=""
          style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: .5em; color: #fff; vertical-align: middle; padding-left: 10px;">
          Coach 2</span>
          <br/>The Purpose of <span
            _ngcontent-wma-c212="" class="text-emphasis">Role Clarification</span></h1>
      </div>
      <div _ngcontent-wma-c212="" class="header icon-heading">
        <div _ngcontent-wma-c212=""><i _ngcontent-wma-c212="" class="fa-solid fa-bullseye"
            style="color: #fff; font-size: 2em;"><span _ngcontent-wma-c212=""
              style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: .5em; color: #fff; vertical-align: middle; padding-left: 10px;">
              new user medium</span></i></div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(255, 255, 255);">
      <div _ngcontent-wma-c212="" class="step-title"> INTRODUCTION </div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">0.1 - </b> You recognized
                  an appropriate opportunity to use Role Clarification. The Role Clarification process should start at
                  the beginning of supervision, ideally during your first contact, to establish rapport and clarify
                  the rules, roles, and expectations. Research demonstrates that outcomes are improved when staff
                  ensures JIIs understand the purpose and expectations. </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="tableDataFormat"> &nbsp;&nbsp;&nbsp; There are no improvements to
                  report. </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(248, 248, 248);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">1</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" style="margin-bottom: 0px;" class="ng-star-inserted">STEP 1 - Identify the agency's
          (county's) goals &amp; purpose for supervision.</h3>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.1 - </b> You used
                  informal and conversational language to articulate the agency's purpose and goals for delivering
                  supervision services. </td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.2 - </b> In Step One,
                  you enhanced the collaborative and relationship-building dimensions of Role Clarification (RC) by
                  using a tone of voice conducive to creating engagement, building rapport, and expressing optimism
                  about the supervision process. </td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.3 - </b> Great job
                  fostering engagement early in the conversation!



                  <!--bindings={
"ng-reflect-ng-if": "false"
}-->
                </td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.4 - </b> Some JIIs
                  might believe that supervision exists solely to monitor and enforce their court/parole board
                  conditions (rules) or to look for violations that will send them back to jail or prison. You did a
                  fantastic job taking a moment to provide clarification on this misunderstanding. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><br/><br/><br/><br/></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.6 - </b> During Step
                  One, the JII shared additional information. You did an excellent job refraining from judgment and
                  advice, opting instead to respond by actively listening and acknowledging their comments and
                  concerns. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.7 - </b> In Step One,
                  the JII shared additional information. You did an outstanding job refraining from judgment and
                  advice, choosing instead to respond with expressions of optimism and instilling hope. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><br/><br/><br/> </td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.9 - </b> In Step One,
                  you incorporated an advanced approach by asking open-ended questions that encouraged the JII to
                  express any confusion or seek clarification. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.10 - </b> Great
                  strategy! Inviting the JII to share their thoughts on the purpose and goals of supervision is an
                  excellent way to assess their understanding and willingness to engage in the process. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object],[object Object"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">1.1 - </b> The initial
                  stage of Role Clarification involves briefly explaining the overarching purpose of supervision and
                  the agency's (county's, district's) objectives in delivering services to Justice-Involved
                  Individuals (JIIs). The description should align with the agency's mission statement, employing
                  broad and general language applicable to anyone under supervision. It's crucial to emphasize that
                  this step is not about the officer/staff or tailored to the specific JII. Be cautious not to
                  personalize the purpose and goals in this phase (e.g., "To ensure 'you' don't re-offend" or "To
                  equip 'you' with the skills 'you' need to change 'your' behavior."). Providing a general explanation
                  at this point minimizes potential defensiveness and promotes rapport-building. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(255, 255, 255);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">2</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" style="margin-bottom: 0px;" class="ng-star-inserted">STEP 2 - Ask the JII to
          identify what they hope to accomplish during supervision</h3>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">2.1 - </b> Inquiring
                  about the JII's personal goals is an excellent method for understanding their intrinsic motivators.
                  While it's acceptable to incorporate supervision goals into the conversation, be cautious not to
                  exclusively narrow the focus to supervision goals. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">2.1 - </b> You skillfully
                  prompted the JII to share their goals and aspirations. As you draw out and delve into their goals,
                  it's important to avoid imposing your own objectives for the JII, steering them toward specific
                  areas, or unintentionally influencing their thoughts. Step Two is dedicated to building rapport and
                  gaining a deeper understanding of the JII. Maintain a neutral stance in the conversation, allowing
                  the individual to freely discuss meaningful aspects of their life. It's perfectly acceptable if
                  their identified goals differ from the ones you may have in mind for them. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(248, 248, 248);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">3</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" style="margin-bottom: 0px;" class="ng-star-inserted">STEP 3 - Identify what your
          role is, as a representative of the agency, and what you hope to accomplish</h3>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.1 - </b> You explained
                  that you have several roles in supervising the JII. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.2 - </b> You described
                  your goals and what you hope to accomplish in working with the JII. This is an excellent way to
                  establish collaboration and help the person recognize the purpose of your interactions and any
                  interventions you may provide. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.3 - </b> You
                  effectively fostered collaborative goal sharing by intertwining some of the JII's goals from Step
                  Two with your role and objectives in Step Three. Well done! <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.4 - </b> You did an
                  outstanding job in Step Three by encouraging the JII to provide input. This segment of the
                  conversation presents an excellent opportunity to foster collaboration and involve them actively in
                  their supervision. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object],[object Object"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">3.1 - </b> Research shows
                  that staff who maintain a balanced approach to supervision have the best outcomes with the people
                  they work with. To achieve this, we must consistently balance or blend our dual roles. Help the JII
                  understand that, at all times, you will be acting in both a 'law-enforcement' (holding accountable,
                  monitoring compliance, reporting to the courts/parole board) and a 'social work' (providing
                  assistance and support, teaching skill-building, promoting positive behavioral change) capacity.
                  Subtle messages that imply that our role is contingent upon the JII's actions (e.g., 'I'm willing to
                  work with you and help you, unless...' or '...and then I'll have to take off my social work hat and
                  put on my law enforcement hat.') carry a veiled threat or warning that may harm the supervision
                  relationship. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div _ngcontent-wma-c212="" class="step-section page-break ng-star-inserted" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(255, 255, 255);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">4</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" style="margin-bottom: 0px;" class="ng-star-inserted">STEP 4 - Define the
          supervision process</h3>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.1 - </b> Great job
                  acknowledging that the "negotiable/non-negotiable" sub-step contributes to empowering individuals by
                  highlighting that they will have some control in their supervision (i.e., emphasizing autonomy). <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.2 - </b> You went
                  beyond emphasizing the person's autonomy by communicating the boundaries, requirements, and
                  expectations surrounding the JII's ability to make decisions and exercise control. This approach
                  provides clarity and sets realistic expectations for the JII. Well done! <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><br/><br/><br/></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.4 - </b> You offered
                  examples of situations where the JII can make decisions and exercise control (negotiable) and where
                  they are not allowed to (non-negotiable). This is an excellent way to reinforce your point and
                  provide clarification. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.5 - </b> You did a
                  fantastic job highlighting a few non-negotiable items that may change due to the JII's actions.
                  Helping a JII recognize how reporting schedules (frequency and location), drug tests, and other
                  non-negotiable conditions may lessen in response to their behavior is empowering. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object],[object Object"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">4.1 - </b>
                  Justice-involved individuals who accurately understand the rules, roles, and expectations have
                  better outcomes than those who do not. Use the conversation in step four to encourage the JII to ask
                  questions, express concerns, and ensure understanding. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div><!--bindings={
"ng-reflect-ng-if": "true"
}-->
    <div _ngcontent-wma-c212="" class="step-section page-break ng-star-inserted" ng-reflect-ng-style="[object Object]"
      style="background-color: rgb(248, 248, 248);">
      <div _ngcontent-wma-c212="" class="step-title"> STEP <span _ngcontent-wma-c212="" class="step-background"><span
            _ngcontent-wma-c212="" class="step-number">5</span></span></div>
      <div _ngcontent-wma-c212="" class="step-title-line"></div>
      <div _ngcontent-wma-c212="" class="header"
        style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 _ngcontent-wma-c212="" class="ng-star-inserted">STEP 5 - Identify and discuss expectations of
          confidentiality</h3><!--bindings={
"ng-reflect-ng-if": "true"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Strengths </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.1 - </b> When
                  explaining the expectations of confidentiality, you used clear and specific language to describe the
                  type of information that would be shared with others—an excellent way to avoid any potential
                  confusion. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level1"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.2 - </b> You excelled
                  in Step Five of Role Clarification by providing accurate and thorough information to the JII about
                  confidentiality. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level2"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><br/><br/><br/><br/></td>
              </tr>
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.4 - </b> Great job
                  creating dialogue by inviting the person to share their thoughts and concerns about information
                  being shared. This approach helps to address any apprehensions the person may have and fosters open
                  communication. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object],[object Object"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
      <div _ngcontent-wma-c212="" class="feedback-section-title"> Improvements (Opportunities) </div>
      <div _ngcontent-wma-c212="" class="tableBody step-table">
        <div _ngcontent-wma-c212="" class="table-responsive">
          <table _ngcontent-wma-c212="" class="table-report">
            <tbody _ngcontent-wma-c212="">
              <tr _ngcontent-wma-c212="" class="ng-star-inserted">
                <td _ngcontent-wma-c212="" class="level3"> &nbsp; </td>
                <td _ngcontent-wma-c212="" class="tableDataFormat"><b _ngcontent-wma-c212="">5.1 - </b> Collaborating
                  and sharing information with others on the team is always purposeful. Frequently, it helps us reach
                  one or more of the agency's overarching goals (e.g., informing the courts /parole board to protect
                  public safety and sharing concerns with treatment providers to promote behavior change). Therefore,
                  when describing the expectations for confidentiality, you should help the JII understand the purpose
                  and circumstances for sharing their information. <!--bindings={
"ng-reflect-ng-if": "false"
}--></td>
              </tr><!--bindings={
"ng-reflect-ng-for-of": "[object Object]"
}--><!--bindings={
"ng-reflect-ng-if": "false"
}-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>




</div>




<div *ngIf="surveyId != 'yNlh73iAWthcWYMnOogK' ">
<div *ngIf="data$ | async as data">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">
    <div class="card" >
      <div class="header report-heading-results">
        <h1>Results</h1>
      </div>
      <div class="header report-scoring-table">
        <div class="table table-responsive table-bordered">
          <table >
            <thead>
              <tr>
                <th scope="col">Strengths</th>
                <th scope="col">Improvements</th>
                <th scope="col">New User</th>
                <th scope="col">Intermediate User</th>
                <th scope="col">Advanced User</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ data.survey.processedResults.pscore}} </td>
                <td>{{ data.survey.processedResults.oscore}} </td>
                <td>{{ data.survey.processedResults.nscore}}&nbsp;&nbsp;&nbsp;
                  ({{data.survey.processedResults.nubm.length}}/20)</td>
                <td>{{ data.survey.processedResults.iscore}}&nbsp;&nbsp;&nbsp;
                  ({{data.survey.processedResults.iubm.length}}/17) </td>
                <td>{{ data.survey.processedResults.ascore}}&nbsp;&nbsp;&nbsp;
                  ({{data.survey.processedResults.aubm.length}}/12) </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>






      <div class="col-xs-12 " style="background-color: #232E41;">
        <div class="header hero-heading" >
          <h1 class="hero-heading-class" *ngIf="data.recording.skill == 'Role Clarification'" >The Purpose of <span class="text-emphasis">Role Clarification</span></h1>
          <h1 class="hero-heading-class" *ngIf="data.recording.skill == 'Effective Use of Reinforcement'" >The Purpose of <span class="text-emphasis">Effective Use of Reinforcement</span></h1>
          <h1 class="hero-heading-class" *ngIf="data.recording.skill == 'Effective Use of Disapproval'" >The Purpose of <span class="text-emphasis">Effective Use of Disapproval</span></h1>
          <h1 class="hero-heading-class" *ngIf="data.recording.skill == 'Teaching the Cognitive Model'" >The Purpose of <span class="text-emphasis">Teaching the Cognitive Model</span></h1>
          <h1 class="hero-heading-class" *ngIf="data.recording.skill == 'Applying Cognitive Model'"><span class="text-emphasis">The Purpose of Applying Cognitive Model</span></h1>
          <h1 class="hero-heading-class" *ngIf="data.recording.skill == 'Applying Problem-Solving'"><span class="text-emphasis">The Purpose of Applying Problem-Solving</span></h1>
          <h1 class="hero-heading-class" *ngIf="data.recording.skill == 'Effective use of Authority'"><span class="text-emphasis">The Purpose of Effective use of Authority</span></h1>  
          <h1 class="hero-heading-class" *ngIf="data.recording.skill == 'Teaching Problem-Solving'"><span class="text-emphasis">The Purpose of Teaching Problem-Solving</span></h1>  
        </div>
        <div class="header icon-heading" >
          <div >
            <i class="fa-solid fa-bullseye" style="color: #fff; font-size: 2em;">            
            <span style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: .5em; color: #fff; vertical-align: middle; padding-left: 10px;" >
              {{ userTypeAndLevel.userType }} / {{ userTypeAndLevel.level }}</span>
            </i>
          </div>
        </div>
      </div>




      <div class="step-section page-break" [ngStyle]="{ 'background-color': getBackgroundColor(0) }" *ngIf="hasStep(data.recording.skill, 0)">


          <div class="step-title">
              INTRODUCTION 
          </div>
          <div class="step-title-line">
          </div>

        <div class="feedback-section-title">
          Strengths
        </div>

      <!--  <div class="row">
          <div class="col-xs-12" style="margin-right: 0px; padding-right: 30px; margin-left: 1em">
            <div class="row" *ngFor="let fb of data.step0p; let i = index" style="margin-top: 20px; margin-bottom: 20px;">

              <div class="col-12 feedback-text">
                <span  style="display: inline-block;" [class]="levelClass(fb)">
                  &nbsp;

                </span>
                <b>0.{{i+1}}</b> {{ fb.Explanation}} <span style="color: #454F59"
                *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{ fb.Question }}, {{ fb.Code }}, {{
                fb.Scale }}, {{ fb.Points }}]</span>
              </div>
            </div>
            <div class="row" *ngIf="data.step0p.length == 0" >
              <div class="col-12 feedback-text">
                &nbsp;&nbsp;&nbsp; There are no strengths to report.
              </div>
            </div>
          </div>
  
        </div> -->

        <div class="tableBody step-table">
          <div class="table-responsive">
            <table class="table-report " >
              <tbody>
                <tr *ngFor="let fb of data.step0p; let i = index">
                  <td [class]="levelClass(fb)" [title]="levelText(fb)">
                    &nbsp;
                  </td>
                  <td class="tableDataFormat">
                    <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('p',fb.Code)" (change)="toggleCheckbox('p', fb.Code, $event)"> &nbsp;
                    <b>0.{{i+1}} - </b> {{ fb.Explanation}} <span
                      *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{ fb.Question }}, {{ fb.Code }}, {{
                      fb.Scale }}, {{ fb.Points }}]</span>

                    <span *ngIf="this.hasComment(fb)">
                      <br><br><b>Comment: {{ this.getComment(fb) }}</b>
                    </span>

                  </td>
                </tr>
                <tr *ngIf="data.step0p.length == 0">
                  <td class="tableDataFormat">
                    &nbsp;&nbsp;&nbsp; There are no strengths to report.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> 

      <!--  <div class="tableBody step-table">
          <div class="table-responsive">
            <table class="table-report">
              <tbody>
                <tr *ngFor="let fb of data.step0p; let i = index">
                                    <td [class]="levelClass(fb)" [title]="levelText(fb)">

                    &nbsp;
                  </td>
                  <td>
                    <b>0.{{i+1}}</b> {{ fb.Explanation}} <span
                      *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{ fb.Question }}, {{ fb.Code }}, {{
                      fb.Scale }}, {{ fb.Points }}]</span>
                  </td>
                </tr>
                <tr *ngIf="data.step0p.length == 0">
                  <td>
                    &nbsp;&nbsp;&nbsp; There are no strengths to report.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->

        <div class="feedback-section-title">
          Improvements (Opportunities)
        </div>
        <div class="tableBody step-table">
          <div class="table-responsive">
            <table class="table-report">
              <tbody>
                <tr *ngFor="let fb of data.step0o | slice:0:this.sliceValue; let i = index">
                                    <td [class]="levelClass(fb)" [title]="levelText(fb)">

                    &nbsp;
                  </td>
                  <td class="tableDataFormat">
                    <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('o',fb.Code)" (change)="toggleCheckbox('o', fb.Code, $event)"> &nbsp;
                    <b>0.{{i+1}} - </b> {{ fb.Explanation}} <span
                      *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{ fb.Question }}, {{ fb.Code }}, {{
                      fb.Scale }}, {{ fb.Points }}]</span>
                  </td>
                </tr>
                <tr *ngIf="data.step0o.length == 0">
                  <td class="tableDataFormat">
                    &nbsp;&nbsp;&nbsp; There are no improvements to report.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="feedback-section-title">
          Notes
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="!this.myReport && authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])"> <!-- WES FIX ME -->
          <div>
            <div>
              <b>My Notes</b> - Not visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea [(ngModel)]="this.reportNotes.step0.coachesNote"  matInput rows="6"></textarea>
            </mat-form-field>
            <div>
              <b>Learner Notes</b> - Visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step0.learnersNote"  matInput rows="6"></textarea>            
            </mat-form-field>
          </div>
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="this.myReport"> <!-- WES FIX ME -->
          <div class="preformatted">
            {{this.reportNotes?.step0?.learnersNote}}
          </div>
        </div>


      </div>


    <div class="step-section page-break" [ngStyle]="{ 'background-color': getBackgroundColor(1) }" *ngIf="hasStep(data.recording.skill, 1)">
      <div class="step-title">
          STEP <span class="step-background"><span class="step-number">1</span></span>
      </div>
      <div class="step-title-line">
      </div>

      <div class="header" style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 *ngIf="data.recording.skill == 'Role Clarification'" style="margin-bottom: 0px;">STEP 1 - Identify the agency's (county's) goals &
          purpose for supervision.</h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Reinforcement'" style="margin-bottom: 0px;">STEP 1 - Clearly state the desirable behavior and,
          if necessary, explain why you liked it. </h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Disapproval'" style="margin-bottom: 0px;">STEP 1 - Clearly state the problematic
          behavior. </h3>
        <h3 *ngIf="data.recording.skill == 'Teaching the Cognitive Model'" style="margin-bottom: 0px;">STEP 1 - Introduce the Cognitive Model
          (Engage). </h3>
        <h3 *ngIf="data.recording.skill == 'Applying Cognitive Model'" style="margin-bottom: 0px;">STEP 1 - Identify a situation where the Cognitive
          Model may be used and gain the person's agreement to apply it to their situation.</h3>
        <h3 *ngIf="data.recording.skill == 'Applying Problem-Solving'" style="margin-bottom: 0px;">STEP 1 - Identify a situation where problem-solving may be used and gain the person's agreement to apply the skill to their problem.</h3>
        <h3 *ngIf="data.recording.skill == 'Effective use of Authority'" style="margin-bottom: 0px;">STEP 1 - Involves deciding if Effective use of Authority is the appropriate skill to use in the given situation. </h3>
        <h3 *ngIf="data.recording.skill == 'Teaching Problem-Solving'" style="margin-bottom: 0px;">STEP 1 -  Create engagement by explaining the purpose and benefits of problem-solving. </h3>
      </div>
      <div class="feedback-section-title">
        Strengths
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">
            <tbody>
              <tr *ngFor="let fb of data.step1p; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('p',fb.Code)" (change)="toggleCheckbox('p', fb.Code, $event)"> &nbsp;
                  <b>1.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step1p.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no strengths to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="feedback-section-title">
        Improvements (Opportunities)
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">

            <tbody>
              <tr *ngFor="let fb of data.step1o | slice:0:this.sliceValue; let i = index">
                <td [class]="levelClass(fb)" [title]="levelText(fb)">
                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('o',fb.Code)" (change)="toggleCheckbox('o', fb.Code, $event)"> &nbsp;
                  <b>1.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step1o.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no improvements to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>




        <div class="feedback-section-title">
          Notes
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])"> <!-- WES FIX ME -->
          <div>
            <div>
              <b>My Notes</b> - Not visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea [(ngModel)]="this.reportNotes.step1.coachesNote"  matInput rows="6"></textarea>
            </mat-form-field>
            <div>
              <b>Learner Notes</b> - Visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step1.learnersNote"  matInput rows="6"></textarea>            
            </mat-form-field>
          </div>
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="this.myReport"> <!-- WES FIX ME -->
          <div class="preformatted">
            {{this.reportNotes?.step1?.learnersNote}}
          </div>
        </div>

    </div>


  <div class="step-section page-break" [ngStyle]="{ 'background-color': getBackgroundColor(2) }" *ngIf="hasStep(data.recording.skill, 2)">
      <div class="step-title">
          STEP <span class="step-background"><span class="step-number">2</span></span>
      </div>
      <div class="step-title-line">
      </div>

      <div class="header" style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 *ngIf="data.recording.skill == 'Role Clarification'" style="margin-bottom: 0px;">STEP 2 - Ask the JII to identify what they hope to
          accomplish during supervision</h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Reinforcement'" style="margin-bottom: 0px;">STEP 2 - Ask the person to describe the
          immediate benefits of engaging in the behavior.</h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Disapproval'" style="margin-bottom: 0px;">STEP 2 - Ask the person to describe the
          immediate consequences of engaging in the problematic behavior.</h3>
        <h3 *ngIf="data.recording.skill == 'Teaching the Cognitive Model'" style="margin-bottom: 0px;">STEP 2 - Teach the Cognitive Model (Teach).
        </h3>
        <h3 *ngIf="data.recording.skill == 'Applying Cognitive Model'" style="margin-bottom: 0px;">STEP 2 - Apply the Cognitive Model to the
          person's situation.</h3>
        <h3 *ngIf="data.recording.skill == 'Applying Problem-Solving'" style="margin-bottom: 0px;">STEP 2 - Apply the problem-solving processl to the person's problem</h3>
        <h3 *ngIf="data.recording.skill == 'Effective use of Authority'" style="margin-bottom: 0px;">STEP 2 - Present the available choices along with the attendant consequences.</h3>
        <h3 *ngIf="data.recording.skill == 'Teaching Problem-Solving'" style="margin-bottom: 0px;">STEP 2 -  Teach the problem-solving process.</h3>
      </div>
      <div class="feedback-section-title">
        Strengths
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">
            <tbody>
              <tr *ngFor="let fb of data.step2p; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('p',fb.Code)" (change)="toggleCheckbox('p', fb.Code, $event)"> &nbsp;

                  <b>2.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step2p.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no strengths to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="feedback-section-title">
        Improvements (Opportunities)
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">
            <tbody>
              <tr *ngFor="let fb of data.step2o | slice:0:this.sliceValue; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('o',fb.Code)" (change)="toggleCheckbox('o', fb.Code, $event)"> &nbsp;

                  <b>2.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step2o.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no improvements to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>




        <div class="feedback-section-title">
          Notes
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])"> <!-- WES FIX ME -->
          <div>
            <div>
              <b>My Notes</b> - Not visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step2.coachesNote" matInput rows="6"></textarea>
            </mat-form-field>
            <div>
              <b>Learner Notes</b> - Visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step2.learnersNote" matInput rows="6"></textarea>            
            </mat-form-field>
          </div>
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="this.myReport"> <!-- WES FIX ME -->
          <div class="preformatted">
            {{this.reportNotes?.step2?.learnersNote}}
          </div>
        </div>

    </div>




  <div class="step-section page-break" [ngStyle]="{ 'background-color': getBackgroundColor(3) }" *ngIf="hasStep(data.recording.skill, 3)">
    <div class="step-title">
      STEP <span class="step-background"><span class="step-number">3</span></span>
    </div>
    <div class="step-title-line">
    </div>

      <div class="header" style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 *ngIf="data.recording.skill == 'Role Clarification'" style="margin-bottom: 0px;">STEP 3 - Identify what your role is, as a
          representative of the agency, and what you hope to accomplish</h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Reinforcement'" style="margin-bottom: 0px;">STEP 3 - Ask the person to describe the
          future benefits if they continue to engage in the behavior.</h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Disapproval'" style="margin-bottom: 0px;">STEP 3 - Ask the person to describe the
          future consequences if they continue to engage in the behavior.</h3>
        <h3 *ngIf="data.recording.skill == 'Teaching the Cognitive Model'" style="margin-bottom: 0px;">STEP 3 - Demonstrate the Cognitive Model
          using a neutral practice example (Demonstrate). </h3>
        <h3 *ngIf="data.recording.skill == 'Applying Cognitive Model'" style="margin-bottom: 0px;">STEP 3 - Gain the person's commitment to use the
          Cognitive Model in the future.</h3>
        <h3 *ngIf="data.recording.skill == 'Applying Problem-Solving'" style="margin-bottom: 0px;">STEP 3 - Gain the person's commitment to continue practicing problem-solving; provide guidance on homework expectations and ongoing practice.</h3>
        <h3 *ngIf="data.recording.skill == 'Effective use of Authority'" style="margin-bottom: 0px;">STEP 3 - FIX ME MISSING STEP TITLE</h3>
        <h3 *ngIf="data.recording.skill == 'Teaching Problem-Solving'" style="margin-bottom: 0px;">STEP 3 -  Demonstrate the problem-solving process with a simple practice example.</h3>
      </div>
      <div class="feedback-section-title">
        Strengths
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">
            <tbody>
              <tr *ngFor="let fb of data.step3p; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('p',fb.Code)" (change)="toggleCheckbox('p', fb.Code, $event)"> &nbsp;

                  <b>3.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step3p.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no strengths to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="feedback-section-title">
        Improvements (Opportunities)
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">

            <tbody>
              <tr *ngFor="let fb of data.step3o | slice:0:this.sliceValue; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('o',fb.Code)" (change)="toggleCheckbox('o', fb.Code, $event)"> &nbsp;

                  <b>3.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step3o.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no improvements to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>




        <div class="feedback-section-title">
          Notes
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])"> <!-- WES FIX ME -->
          <div>
            <div>
              <b>My Notes</b> - Not visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea [(ngModel)]="this.reportNotes.step3.coachesNote"  matInput rows="6"></textarea>
            </mat-form-field>
            <div>
              <b>Learner Notes</b> - Visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step3.learnersNote" matInput rows="6"></textarea>            
            </mat-form-field>
          </div>
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="this.myReport"> <!-- WES FIX ME -->
          <div class="preformatted">
            {{this.reportNotes?.step3?.learnersNote}}
          </div>
        </div>

  </div>

  <div class="step-section page-break" [ngStyle]="{ 'background-color': getBackgroundColor(4) }" *ngIf="hasStep(data.recording.skill, 4)">
    <div class="step-title">
      STEP <span class="step-background"><span class="step-number">4</span></span>
    </div>
    <div class="step-title-line">
    </div>
      <div class="header" style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 *ngIf="data.recording.skill == 'Role Clarification'" style="margin-bottom: 0px;">STEP 4 - Define the supervision process</h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Reinforcement'" style="margin-bottom: 0px;">STEP 4 - Gain the person's commitment to
          continue to engage in the behavior in the future.</h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Disapproval'" style="margin-bottom: 0px;">STEP 4 - Ask the person to identify and
          discuss pro-social alternatives that could be used in place of the problematic behavior.</h3>
        <h3 *ngIf="data.recording.skill == 'Teaching the Cognitive Model'" style="margin-bottom: 0px;">STEP 4 - Gain the persn's commitment to
          applying the Cognitive Model to their personal situations (Practice). </h3>
        <h3 *ngIf="data.recording.skill == 'Teaching the Cognitive Model'">STEP 4 - Gain the person's commitment to practice problem-solving.</h3>
      </div>
      <div class="feedback-section-title">
        Strengths
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">
            <tbody>
              <tr *ngFor="let fb of data.step4p; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('p',fb.Code)" (change)="toggleCheckbox('p', fb.Code, $event)"> &nbsp;

                  <b>4.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step4p.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no strengths to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="feedback-section-title">
        Improvements (Opportunities)
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">

            <tbody>
              <tr *ngFor="let fb of data.step4o | slice:0:this.sliceValue; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('o',fb.Code)" (change)="toggleCheckbox('o', fb.Code, $event)"> &nbsp;

                  <b>4.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step4o.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no improvements to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



        <div class="feedback-section-title">
          Notes
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])"> <!-- WES FIX ME -->
          <div>
            <div>
              <b>My Notes</b> - Not visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step4.coachesNote" matInput rows="6"></textarea>
            </mat-form-field>
            <div>
              <b>Learner Notes</b> - Visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step4.learnersNote" matInput rows="6"></textarea>            
            </mat-form-field>
          </div>
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="this.myReport"> <!-- WES FIX ME -->
          <div class="preformatted">
            {{this.reportNotes?.step4?.learnersNote}}
          </div>
        </div>

  </div>

  <div class="step-section page-break" [ngStyle]="{ 'background-color': getBackgroundColor(5) }" *ngIf="hasStep(data.recording.skill, 5)">
      <div class="step-title">
        STEP <span class="step-background"><span class="step-number">5</span></span>
    </div>
    <div class="step-title-line">
    </div>

      <div class="header" style="color: #454F59; padding-left: 0px; margin-bottom: 0px; margin-top: 10px;">
        <h3 *ngIf="data.recording.skill == 'Role Clarification'">STEP 5 - Identify and discuss expectations of
          confidentiality</h3>
        <h3 *ngIf="data.recording.skill == 'Effective Use of Disapproval'">STEP 5 - Gain the person's commitment to
          using the pro-social alternative instead of the problematic behavior in the future.</h3>
      </div>
      <div class="feedback-section-title">
        Strengths
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">
            <tbody>
              <tr *ngFor="let fb of data.step5p; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('p',fb.Code)" (change)="toggleCheckbox('p', fb.Code, $event)"> &nbsp;

                  <b>5.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step5p.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no strengths to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="feedback-section-title">
        Improvements (Opportunities)
      </div>
      <div class="tableBody step-table">
        <div class="table-responsive">
          <table class="table-report">

            <tbody>
              <tr *ngFor="let fb of data.step5o | slice:0:this.sliceValue; let i = index">
                                  <td [class]="levelClass(fb)" [title]="levelText(fb)">

                  &nbsp;
                </td>
                <td class="tableDataFormat">
                  <input *ngIf="authService.checkUserRoles([Role.Coach])"  type="checkbox" style="width: 20px; height: 20px" [checked]="hasSelectedCheckbox('o',fb.Code)" (change)="toggleCheckbox('o', fb.Code, $event)"> &nbsp;

                  <b>5.{{i+1}} - </b> {{ fb.Explanation}} <span *ngIf="authService.checkUserRoles([Role.PraxusAdmin])">[{{
                    fb.Question }}, {{ fb.Code }}, {{ fb.Scale }}, {{ fb.Points }}]</span>
                </td>
              </tr>
              <tr *ngIf="data.step5o.length == 0">
                <td class="tableDataFormat">
                  &nbsp;&nbsp;&nbsp; There are no improvements to report.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



        <div class="feedback-section-title">
          Notes
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])"> <!-- WES FIX ME -->
          <div>
            <div>
              <b>My Notes</b> - Not visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step5.coachesNote" matInput rows="6"></textarea>
            </mat-form-field>
            <div>
              <b>Learner Notes</b> - Visible to the Learner
            </div>
            <mat-form-field class="example-full-width mb-3"  appearance="fill" >
              <textarea  [(ngModel)]="this.reportNotes.step5.learnersNote" matInput rows="6"></textarea>            
            </mat-form-field>
          </div>
        </div>
        <div style="padding-top: 10px; padding-left: 20px; padding-right: 20px;" *ngIf="this.myReport"> <!-- WES FIX ME -->
          <div class="preformatted">
            {{this.reportNotes?.step5?.learnersNote}}
          </div>
        </div>
      
  </div>

  <div>
    <button class="me-1 primaryButton"  mat-raised-button  *ngIf="authService.checkUserRoles([Role.Coach, Role.PraxisAdmin])" title="Save Report" (click)="saveReport()"> 
    
      <mat-icon style="vertical-align: middle; color: #fff;">save</mat-icon>
          <span style="vertical-align: middle; color: #fff;">Save Report</span>
   
  </button>

  <button class="me-1 primaryButton"  mat-raised-button  *ngIf="authService.checkUserRoles([Role.Coach, Role.PraxisAdmin])" title="Publish Report" (click)="publishReport()"> 
    
    <mat-icon style="vertical-align: middle; color: #fff;">save</mat-icon>
        <span style="vertical-align: middle; color: #fff;">Publish Report to Learner</span>
 
</button>

  </div>

</div>

</div>

<!-- div _ngcontent-wma-c212="" class="col-xs-12" style="background-color: #232E41;">
  <div _ngcontent-wma-c212="" class="header hero-heading">
    <h1 _ngcontent-wma-c212="" class="hero-heading-class ng-star-inserted">The Purpose of <span
        _ngcontent-wma-c212="" class="text-emphasis">Role Clarification</span></h1>
  </div>
  <div _ngcontent-wma-c212="" class="header icon-heading">
    <div _ngcontent-wma-c212=""><i _ngcontent-wma-c212="" class="fa-solid fa-bullseye"
        style="color: #fff; font-size: 2em;"><span _ngcontent-wma-c212=""
          style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: .5em; color: #fff; vertical-align: middle; padding-left: 10px;">
          new user medium</span></i></div>
  </div>
</div -->

  <!--div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="(authService.checkUserRoles([Role.PraxusAdmin])) && data$ | async as data">
    <div class="card report-card">
      <div class="header hero-heading"  style="background-color: #232E41;">
        <h1 class="hero-heading-class">Benchmark Comparison</h1>
      </div>

      <div style="padding: 20px; margin-left: 1em;" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <table width="100%">
          <tr>
            <th class="feedback-section-title">Benchmark</th>
            <th class="feedback-section-title">Coach 1</th>
            <th class="feedback-section-title">Coach 2</th>
          </tr>
          <tr *ngFor="let fb of data.survey.processedResults.nubm">
            <td class="bench-row">NUBM - {{ fb.Code}}</td>
            <td style="color:green;">✓</td>
            <td style="color:green;">{{ this.returnTrueXPercentOfTheTime(90) ? '' : '✓' }}</td>
          </tr>

          <tr *ngFor="let fb of data.survey.processedResults.iubm">
            <td class="bench-row">IUBM - {{ fb.Code}}</td>
            <td style="color:green;">✓</td>
            <td style="color:green;">{{ this.returnTrueXPercentOfTheTime(70) ? '' : '✓' }}</td>
          </tr>

          <tr *ngFor="let fb of data.survey.processedResults.aubm">
            <td class="bench-row">AUBM - {{ fb.Code}}</td>
            <td style="color:green;">✓</td>
            <td style="color:green;">{{ this.returnTrueXPercentOfTheTime(60) ? '' : '✓' }}</td>
          </tr>
        </table>

      </div>
    </div>
  </div -->



  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="authService.checkUserRoles([Role.PraxusAdmin]) && data$ | async as data">
    <div class="card report-card">
      <div class="header report-heading-results">
        <h1>Benchmarks</h1>
      </div>
      <div class="tableBody step-table" style="margin-left: 2em;">
        <div class="table-responsive">
          <table class="table-report">
            <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Skill Level</th>
                <th scope="col">Step</th>
                <th scope="col">Description</th>
                <th scope="col">Points</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let fb of data.survey.processedResults.nubm">
                <td>
                  {{ fb.Code}}
                </td>
                <td>
                  {{ fb.SkillLevel}}
                </td>
                <td>
                  {{ fb.Step}}
                </td>
                <td>
                  {{ fb.Description}}
                </td>
                <td>
                  {{ fb.Points}}
                </td>
              </tr>
              <tr *ngFor="let fb of data.survey.processedResults.iubm">
                <td>
                  {{ fb.Code}}
                </td>
                <td>
                  {{ fb.SkillLevel}}
                </td>
                <td>
                  {{ fb.Step}}
                </td>
                <td>
                  {{ fb.Description}}
                </td>
                <td>
                  {{ fb.Points}}
                </td>
              </tr>
              <tr *ngFor="let fb of data.survey.processedResults.aubm">
                <td>
                  {{ fb.Code}}
                </td>
                <td>
                  {{ fb.SkillLevel}}
                </td>
                <td>
                  {{ fb.Step}}
                </td>
                <td>
                  {{ fb.Description}}
                </td>
                <td>
                  {{ fb.Points}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="authService.checkUserRoles([Role.PraxusAdmin]) && data$ | async as data ">
    <div class="card report-card">
      <div class="header report-heading-results">
        <h1>Questions</h1>
      </div>
      <div class="tableBody step-table" style="margin-left: 2em;">
        <div class="table-responsive">
          <table class="table-report">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Question</th>
                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let question of data.questions">
                <tr *ngIf="question.shown">
                  <td>
                    {{ question.id }}
                  </td>
                  <td>
                    <span [innerHtml]="question.question"></span>
                  </td>
                  <td>
                    <span [innerHtml]="this.processAnswer(question)"></span>
                  </td>
                </tr>
                <tr
                  *ngIf="question.shown && (data.survey.processedResults.praise.length > 0 || data.survey.processedResults.observations.length > 0 || data.survey.processedResults.nubm.length > 0 || data.survey.processedResults.iubm.length > 0 || data.survey.processedResults.aubm.length > 0)">
                  <td colspan="3">
                    <ul *ngIf="data.survey.processedResults.praise.length > 0">
                      <ng-container *ngFor="let pr of data.survey.processedResults.praise">
                        <li *ngIf="pr.Question == question.id">
                          <b>PRAISE:</b> {{ pr.Points }} - {{ pr.Explanation }}
                        </li>
                      </ng-container>
                    </ul>
                    <ul *ngIf="data.survey.processedResults.observations.length > 0">
                      <ng-container *ngFor="let pr of data.survey.processedResults.observations">
                        <li *ngIf="pr.Question == question.id">
                          <b>OBSERVATION:</b> {{ pr.Points }} - {{ pr.Feedback }}
                        </li>
                      </ng-container>
                    </ul>
                    <ul *ngIf="data.survey.processedResults.nubm.length > 0">
                      <ng-container *ngFor="let pr of data.survey.processedResults.nubm">
                        <li *ngIf="pr.Question == question.id">
                          <b>NUBM:</b> {{ pr.Points }} - {{ pr.Description }}
                        </li>
                      </ng-container>
                    </ul>
                    <ul *ngIf="data.survey.processedResults.iubm.length > 0">
                      <ng-container *ngFor="let pr of data.survey.processedResults.iubm">
                        <li *ngIf="pr.Question == question.id">
                          <b>IUBM:</b> {{ pr.Points }} - {{ pr.Description }}
                        </li>
                      </ng-container>
                    </ul>
                    <ul *ngIf="data.survey.processedResults.aubm.length > 0">
                      <ng-container *ngFor="let pr of data.survey.processedResults.aubm">
                        <li *ngIf="pr.Question == question.id">
                          <b>AUBM:</b> {{ pr.Points }} - {{ pr.Description }}
                        </li>
                      </ng-container>
                    </ul>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="currentUser">
    <div class="card report-card">
      <div class="header report-heading">
        <h1>TESTER REPORT SURVEY</h1>
      </div>
      <div style="padding: 20px;">
    <a target="_blank" href="https://survey.alchemer.com/s3/7337214/Skill-Tree-Decision-Tree-Functionality?squid={{data.recording.id}}&rec={{data.recording.name}}&user={{this.currentUser.fullName}}"><h2>TAKE THE RESULTS SURVEY</h2></a>
  </div>
  </div -->
