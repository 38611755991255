export enum Role {
  PraxusAdmin = 'Praxus Admin',
  AgencyAdmin = 'Agency Admin',
  CoachPeerCoach = "Lead Peer Coach",
  Coach = 'Coach',
  Supervisor = 'Supervisor',
  Student = "Learner",
  DemoLearner = "Demo Learner",
  Developer = "Developer",
  LimitedLearner = "Limited Learner"
}


